
export const GetCurrentDate = ()  => {
  const date = new Date(); // Gets the local date and time

  const currentHours = ('0' + date.getHours()).slice(-2);  // Ensures 2-digit format
  const currentMinutes = ('0' + date.getMinutes()).slice(-2);
  const currentMonth = ('0' + (date.getMonth() + 1)).slice(-2);  // `getMonth()` is 0-indexed
  const currentDate = ('0' + date.getDate()).slice(-2);

  const formattedDate = `${date.getFullYear()}-${currentMonth}-${currentDate}T${currentHours}:${currentMinutes}`;

  return formattedDate;
};

export const CalculateNewDate = (timeInterval: string) => {
  const currentDate = new Date(); // Get current date
  let newDate = new Date(currentDate); // Clone current date

  switch (timeInterval) {
    case "12 Hours":
      newDate.setHours(newDate.getHours() + 12);
      break;
    case "1 day":
      newDate.setDate(newDate.getDate() + 1);
      break;
    case "3 days":
      newDate.setDate(newDate.getDate() + 3);
      break;
    case "7 days":
      newDate.setDate(newDate.getDate() + 7);
      break;
    case "1 month":
      newDate.setMonth(newDate.getMonth() + 1);
      break;
    case "Custom":
      newDate = currentDate;
      break;
    default:
      console.error("Invalid time interval");
  }
  
  const newHours = ('0' + newDate.getHours()).slice(-2);
  const newMinutes = ('0' + newDate.getMinutes()).slice(-2);
  const newMonth = ('0' + (newDate.getMonth() + 1)).slice(-2);
  const newDay = ('0' + newDate.getDate()).slice(-2);

  return `${newDate.getFullYear()}-${newMonth}-${newDay}T${newHours}:${newMinutes}`;
};
  