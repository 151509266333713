import React, { useState } from "react";
import "./DateDropdown.css";
import upArrow from "../../../assets/up-arrow.png"
import downArrow from "../../../assets/down-arrow.png";

const DateDropdown = ({ timeIntervalsArray, setIsDropdownOpen, isDropdownOpen, timeInterval, setTimeInterval }) => {
  const [dropDownImage, setDropDownImage] = useState(upArrow);
  const toggleDropdown = () => {
    if(!isDropdownOpen)
    {
      setIsDropdownOpen(true);
      setDropDownImage(downArrow);
    }
    else {
      setIsDropdownOpen(false);
      setDropDownImage(upArrow);
    }
  };

  const toggleTimeInterval = (timeIntervalItem: string) => {
    setTimeInterval(timeIntervalItem);
  }

  return (
    <div className="timePickerContainer">
      <button
        className="timeIntervalButton"
        onClick={toggleDropdown}>
        <div className="timerIntervalItem">
          {timeInterval}
        </div>
        {isDropdownOpen && (
          <ul className="timeIntervalList">
            {timeIntervalsArray.map((timeIntervalItem: string) => (
              <li
                onClick={() => toggleTimeInterval(timeIntervalItem)}
                key={timeIntervalItem}
                className="timeIntervalListItem">
                <div className="timerIntervalItem">{timeIntervalItem}</div>
              </li>
            ))}
          </ul>
        )}
        <img alt="dropdownimage" className="dropDownIcon" src={dropDownImage}/>
      </button>
    </div>
  );
  };

export default DateDropdown;