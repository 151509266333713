import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAllCollections } from '../../services/opensea/osGetNFTsByCollection';
import "./PicturePage.css";
import { FidgetSpinner } from "react-loader-spinner";
import openseaIcon from "../../assets/opensea-icon.svg";
import backArrowIcon from "../../assets/back-arrow.svg";
import etherscanIcon from "../../assets/etherscan-logo.svg";
import { NFT } from "../../services/models/NFT";
import MakeOffer from "./makeOffer/MakeOffer";
import { useAccount } from 'wagmi';
import { useAppKit } from '@reown/appkit/react';

type MessageType = 'success' | 'error';

const Popup = ({ message, messageType, selectedNFT }: { message: string, messageType: MessageType, selectedNFT: NFT | undefined}) => (
  <div className={`popup ${messageType}`}>
    {messageType === 'success' ? (
        <><svg className="icon" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm-1.707 15.293l-3.586-3.586 1.414-1.414L10.293 13l5.293-5.293 1.414 1.414-6.707 6.707z" />
      </svg>
      <p className="popup-text">{message}</p>
          <div className="offersLinkWrapper">
            <Link
              className="offersLink"
              to={`https://opensea.io/assets/ethereum/${selectedNFT?.contract}/${selectedNFT?.identifier}`}
              target="_blank"
            >
              <p className="offersLinkText">See Offers</p>
            </Link>
          </div>
          </>
    ) : (
        <><svg className="icon" viewBox="0 0 24 24">
          <path fill="currentColor" d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm1 17h-2v-2h2v2zm0-4h-2V7h2v6z" />
        </svg><p>{message}</p></>
    )}
  </div>
);

const PicturePage = () => {
  const routeParams = useParams();
  const [loading, setLoading] = useState(true);
  const [etherscanURL, setEtherscanURL] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState<NFT | undefined>();
  const [isMakeOfferModalOpen, setMakeOfferModalOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<MessageType>('success');
  const { isConnected } = useAccount();
  const { open } = useAppKit();

  useEffect(() => {
    getAllCollections().then((nfts: Array<NFT>) => {
    const nft = nfts.find((nft) => nft.name === routeParams.name);
    if(nft?.collection === 'colorfuel-universal' || nft?.collection === 'colorfuel-primer' || nft?.collection === 'colorfuel-nostalgia') {
      setEtherscanURL(true);
    }
    setSelectedNFT(nft);
    setLoading(false);
    });
   },[routeParams.name]
  )

  const openMakeOfferModal = (value: boolean, message: string, type: 'success' | 'error') => {
    if(isConnected){
      setMakeOfferModalOpen(value);
      setMessageType(type);
      setPopupMessage(message);
    }
    else {
      open({ view : 'Connect' });
    }
  }

  return (
    <>
      <div className="pictureWrapper">
        {loading ? (
          <FidgetSpinner
            visible={true}
            height="400"
            width="400"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            ballColors={["#ff0000", "#00ff00", "#0000ff"]}
            backgroundColor="#F4442E"
          />
        ) : (
          <>
          <div className="mainContainer">
            <div className="pictureInfoContent">
                <div className="pictureContainer-img">
                  <img
                    className="picture"
                    key={selectedNFT?.name}
                    src={selectedNFT?.image_url}
                    alt="nft"
                  />
                </div>
                <div className="pictureContainer gradient">
                  <h1>{selectedNFT?.name.replace(/^#\d+\s*/, '')}</h1>
                  <p className="nftDescription">{selectedNFT?.description}</p>
                  <div className="pictureButtonContainer">
                    <button className="makeOfferButton"
                      onClick={() => openMakeOfferModal(true, "", messageType)}>
                      <div className="pictureButtonContent">
                        <p>Make Offer</p>
                      </div>
                    </button>
                    
                    <Link className="externalLink"
                      to={`https://opensea.io/assets/ethereum/${selectedNFT?.contract}/${selectedNFT?.identifier}`}
                      target="_blank" >
                      <button>
                        <div className="makeOffer">
                          <p>Offers</p>
                        </div>
                      </button>
                    </Link>

                    {isMakeOfferModalOpen && 
                      <MakeOffer onClose={openMakeOfferModal} selectedNFT={selectedNFT} />
                    }
                    {popupMessage &&
                      <Popup message={popupMessage} messageType={messageType} selectedNFT={selectedNFT}/>
                    }
                    <div className="externalLinkContainer">
                      <button>
                        <div className="externalLinkWrapper"> {
                        etherscanURL
                        ?
                        <Link
                        className="externalLink"
                        to={`https://etherscan.io/address/${selectedNFT?.contract}`}
                        target="_blank"
                      >
                        <div className="pictureButtonContent">
                          <img src={etherscanIcon} alt="etherscan icon" />
                        </div>
                      </Link>
                      :
                      <Link
                      className="externalLink"
                      to={`https://etherscan.io/nft/${selectedNFT?.contract}/${selectedNFT?.identifier}`}
                      target="_blank"
                    >
                      <div className="pictureButtonContent">
                        <img src={etherscanIcon} alt="etherscan icon" />
                      </div>
                    </Link>
                        }
                        </div>
                      </button>
                      <button>
                        <div className="externalLinkWrapper">
                        <Link
                          className="externalLink"
                          to={`https://opensea.io/assets/ethereum/${selectedNFT?.contract}/${selectedNFT?.identifier}`}
                          target="_blank"
                        >
                          <div className="pictureButtonContent">
                            <img src={openseaIcon} alt="opensea icon" />
                          </div>
                        </Link>
                        </div>
                      </button>            
                    </div>
                    <Link className="galleryLink" to="/gallery">
                      <div className="redirectGalleryButtonContainer">
                        <img src={backArrowIcon} alt="backarrow icon" />
                        <p>Gallery</p>
                      </div>
                    </Link>
                  </div>
                </div>
            </div>
          </div>
          </>
        )}
      </div>
    </>
  );
};

export default PicturePage;
